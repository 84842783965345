<template>
    <div id="products">
        <div class="main-container">
            <div class="container">
                <!-- <div class="content left col-md-7 col-lg-push-2"></div>
        <aside class="side1 left col-md-2 col-lg-pull-7"></aside>
        <aside class="side2 left col-md-3"></aside> -->
                <div class="row">
                    <div class="col-lg-3 d-none d-lg-block">
                        <div class="sidebar" v-if="categories">
                            <h5>Categories</h5>
                            <div class="contents">
                                <ul>
                                    <li v-for="category in categories" :key="category.id" class="mb-3">
                                        <router-link :to="`/store/products?category=${category.id}`"
                                            :class="{ 'active' : getCategoryParentSelected(category) }">
                                            {{ category.title }} <span
                                                class="count">{{ `(${category.products_count})` }}</span></router-link>
                                        <ul class="sub-link">
                                            <li v-for="child in category.children" :key="child.id">
                                                <router-link :to="`/store/products?category=${child.id}`"
                                                    :class="{ 'active' : $route.query.category == child.id }">
                                                    {{ child.title }} <span
                                                        class="count">{{ `(${child.products_count})` }}</span>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12">
                        <div class="contents-right">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="content-header">
                                        <div class="card img-fluid">
                                            <img class="card-img-top"
                                                src="//cdn.shopify.com/s/files/1/0391/0621/5981/t/3/assets/collection_default_header_4.jpg?v=4288188597487264269"
                                                alt="Category Image"
                                                style="width:100%;object-fit: cover; height: 100%;">
                                            <div class="card-img-overlay">
                                                <h4 class="card-title">Products</h4>
                                                <!-- <h4 v-if="!promotion" class="card-title">Products</h4>
                                                <h4 v-else class="card-title">{{ promotion.title }}</h4> -->
                                            </div>
                                        </div>
                                        <div v-if="products" class="text-right mt-3 mb-3">
                                            items {{ start + 1 }} - {{ (start) + products.length }} of {{ count }} total
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 mt-4 mb-4">
                                    <div v-if="products && products.length > 0" class="products-list">
                                        <div class="row" v-if="!promotion">
                                            <div v-for="product in products" :key="product.id"
                                                class="col-lg-3 col-md-3 col-6">
                                                <div class="product-view">
                                                    <div class="row">
                                                        <div class="col-sm-12 bg-white">
                                                            <div class="box">
                                                                <div class="img-box" :style="{'background-image': `url(${getImageLink(product)})`}">
                                                                    <img :src="getPromoBadge(product)" alt="" class="badge-img">
                                                                </div>
                                                                <div v-if="product.is_sold_out" class="img-bg-sold-out"></div>
                                                                <button v-if="!product.is_sold_out" class="btn" @click="addItemObj(product)">Add to Cart</button>
                                                                <button v-else class="btn btn-sold-out" >Sold Out</button>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 text-center">
                                                            <router-link :to="`/store/products/${product.id}`"><span
                                                                    class="title">{{ product.title }}</span>
                                                            </router-link>
                                                            <div v-if="!isVIP">
                                                                <!-- <span v-if="product.variants[0].compare_at_price && product.variants[0].compare_at_price != 0" class="price compare_at_price">RM
                                                                {{ product.variants[0].compare_at_price? (product.variants[0].compare_at_price).toFixed(2) : 0 }}</span>
                                                                <span class="price">RM
                                                                {{ product.variants[0].price? (product.variants[0].price).toFixed(2) : 0 }}</span> -->
                                                                <div v-if="product.promo_price">
                                                                    <span class="price compare_at_price">RM {{ (product.variants[0].price).toFixed(2) }}</span>
                                                                    <span class="price">RM {{ (product.promo_price).toFixed(2) }}</span>
                                                                </div>
                                                                <div v-else>
                                                                    <span class="price">RM {{ (product.variants[0].price).toFixed(2) }}</span>
                                                                </div>
                                                            </div>
                                                            <span v-else class="price">RM
                                                                0.00</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-else>
                                            <div v-for="productPromo in products" :key="productPromo.id"
                                                class="col-lg-3 col-md-3 col-6">
                                                <div class="product-view">
                                                    <div class="row">
                                                        <div class="col-sm-12 bg-white">
                                                            <div class="box">
                                                                <div class="img-box" :style="{'background-image': `url(${getImageLink(productPromo.product)})`}">
                                                                    <img :src="getPromoBadge(productPromo.product)" alt="" class="badge-img">
                                                                </div>
                                                                <div v-if="productPromo.product.is_sold_out" class="img-bg-sold-out"></div>
                                                                <button v-if="!productPromo.product.is_sold_out" class="btn" @click="addItemObj(productPromo.product)">Add to Cart</button>
                                                                <button v-else class="btn btn-sold-out" >Sold Out</button>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 text-center">
                                                            <router-link :to="`/store/products/${productPromo.product.id}`"><span
                                                                    class="title">{{ productPromo.product.title }}</span>
                                                            </router-link>
                                                            <div v-if="!isVIP">
                                                                <!-- <span v-if="productPromo.product.variants[0].compare_at_price && productPromo.product.variants[0].compare_at_price != 0" class="price compare_at_price">RM
                                                                {{ productPromo.product.variants[0].compare_at_price? (productPromo.product.variants[0].compare_at_price).toFixed(2) : 0 }}</span>
                                                                <span class="price">RM
                                                                {{ productPromo.product.variants[0].price? (productPromo.product.variants[0].price).toFixed(2) : 0 }}</span> -->
                                                                <div v-if="productPromo.product.promo_price">
                                                                    <span class="price compare_at_price">RM {{ (productPromo.product.variants[0].price).toFixed(2) }}</span>
                                                                    <span class="price">RM {{ (productPromo.product.promo_price).toFixed(2) }}</span>
                                                                </div>
                                                                <div v-else>
                                                                    <span class="price">RM {{ (productPromo.product.variants[0].price).toFixed(2) }}</span>
                                                                </div>
                                                            </div>
                                                            <span v-else class="price">RM
                                                                0.00</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="products && products.length == 0" class="products-list">
                                        There is no item with this information.
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div v-if="products && products.length > 0" class="pagination-footer mt-2">
                                        <!-- <ul class="pagination justify-content-center">
                                            <li class="page-item"
                                                :class="{'disabled' : !$route.query.page || $route.query.page == '1'}">
                                                <button class="page-link"
                                                    @click="goto(parseInt($route.query.page) - 1)">
                                                    <span aria-hidden="true">&laquo;</span>
                                                </button>
                                            </li>
                                            <li class="page-item"><a class="page-link"
                                                    href="#">{{ $route.query.page ? $route.query.page : 1 }}</a></li>
                                            <li class="page-item"
                                                :class="{'disabled' : $route.query.page == overallPage || overallPage == 1}">
                                                <button class="page-link"
                                                    @click="goto(parseInt($route.query.page ? $route.query.page : 1) + 1)">
                                                    <span aria-hidden="true">&raquo;</span>
                                                </button>
                                            </li>
                                        </ul> -->

                                        <div class="pagination justify-content-center">
                                            <vue-pagination 
                                                v-if="!promotion"
                                                v-model="currentPage"
                                                @paginate="paginationCallback"
                                                :records="count"  
                                                :per-page="12" 
                                                :options="{
                                                    texts: {count: ''},
                                                    chunk: 5
                                                }"
                                            >
                                            </vue-pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 d-md-none mt-4">
                        <div class="sidebar" v-if="categories">
                            <h5>Categories</h5>
                            <div class="contents">
                                <ul>
                                    <li v-for="category in categories" :key="category.id" class="mb-3" @click="scrollTop()">
                                        <router-link :to="`/store/products?category=${category.id}`"
                                            :class="{ 'active' : getCategoryParentSelected(category) }">
                                            {{ category.title }} <span
                                                class="count">{{ `(${category.products_count})` }}</span></router-link>
                                        <ul class="sub-link">
                                            <li v-for="child in category.children" :key="child.id">
                                                <router-link :to="`/store/products?category=${child.id}`"
                                                    :class="{ 'active' : $route.query.category == child.id }">
                                                    {{ child.title }} <span
                                                        class="count">{{ `(${child.products_count})` }}</span>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import API from './../../../utils/API'
    import { mapMutations, mapGetters } from 'vuex'
    import { checkProductPromo } from './../../../utils/ProductUtils'

    import VuePagination from 'vue-pagination-2'

    export default {
        components: {
            VuePagination
        },
        data() {
            return {
                isVIP: false,
                categories: null,
                products: null,
                count: 0,
                start: 0,
                limit: 12,
                currentPage: 1,
                overallPage: 0,
                promotion: null
            }
        },
        watch: {
            $route(to, from) {
                //starting with zero products to avoid errors
                this.promotion = null
                this.products = []

                if(to.query.page && to.query.page != from.query.page) this.currentPage = parseInt(to.query.page)

                if (to.query.category && to.query.category != from.query.category) this.fetchProducts('category', this
                    .$route.query.category)
                else this.fetchProducts('init') //init like usual
            }
        },
        computed: {
            ...mapGetters(['currentCustomer', 'promotions']),
        },
        methods: {
            ...mapMutations(['addItem']),
            addItemObj(_product){
                this.addItem({ product: _product , quantity: 1 }) //default value add of quantity is 1
            },
            initPromo(){
                this.products.forEach(product => {
                    let prodPromo = checkProductPromo(product)
                    if(prodPromo) { //craete new subproperties if promo exist
                        product.promo_price = prodPromo.promo_price
                        product.promo_badge_url = prodPromo.promo_badge
                    }
                })
            },
            async fetchProducts(_filteredBy, _value) {
                //setting up pagination
                if (this.$route.query.page) this.start = (parseInt(this.$route.query.page) - 1) * this.limit
                else this.start = 0

                let urlPath = '/products?'
                if (_filteredBy == 'init') { //firstly at mounted
                    if (this.$route.query.keywords) urlPath += `keywords=${this.$route.query.keywords}&`
                    if (this.$route.query.category) urlPath += `category=${this.$route.query.category}&`
                    if (this.$route.query.filter) urlPath += `filter=${this.$route.query.filter}&`
                    if (this.$route.query.filter && this.$route.query.filter == 'promotion' && this.$route.query.promoid) {
                        this.promotion = this.promotions.find(i => i._id == this.$route.query.promoid)
                        this.products = this.promotion.product_promos
                        this.count = this.promotion.product_promos.length
                        this.currentPage = 1
                        this.overallPage = 1
                        return ''
                    }
                } else if (_filteredBy == 'category') {
                    urlPath += `category=${this.$route.query.category}&`
                }

                urlPath += `_start=${this.start}&_limit=${this.limit}`
                let res = await API.get(urlPath)
                this.products = res.data.products
                this.count = res.data.count

                //get overall page
                if (this.products && this.products.length > 0) this.overallPage = Math.ceil(this.count / this.limit)
                // console.log('overall page : ', this.overallPage)

                //repaginate
                if(this.$route.query.page) this.currentPage = parseInt(this.$route.query.page)
                else this.currentPage = 1
            },
            getImageLink(_product) {
                if(!_product.variants[0].image || _product.variants[0].image.length == 0) return '/img/default_no_image.jpg'
                else return API.getAPIUrl() + _product.variants[0].image[0].url //get the first image
            },
            getPromoBadge(_product){
                if(_product.promo_badge_url) return API.getAPIUrl() + _product.promo_badge_url
                // if(_product.is_promotion){
                //     if(_product.promo_type == 'raya') return '/img/badge_raya_promo.png'
                //     else if(_product.promo_type == 'limitedtime') return '/img/badge_4_days_only.png'
                // }
            },
            getCategoryParentSelected(_category) {
                if (_category.id == this.$route.query.category) return true

                let result = false
                _category.children.forEach(child => {
                    if (child.id == this.$route.query.category) result = true
                })
                return result
            },
            paginationCallback(_page){
                // console.log(`Page ${_page} was selected. Do something about it now!`)
                this.goto(_page)
            },
            goto(_page) {
                let query = {}
                if (this.$route.query.category) query.category = this.$route.query.category
                if (this.$route.query.filter) query.filter = this.$route.query.filter
                if (this.$route.query.keywords) query.keywords = this.$route.query.keywords
                // if (this.$route.query._limit) query._limit = this.$route.query._limit

                query.page = _page
                this.$router.push({
                    query
                })
                //go top
                this.scrollTop()
            },
            scrollTop(){
                window.scrollTo(0,0)
            }
        },
        async mounted() {
            //fetch data
            await this.fetchProducts('init')

            //init categories sidebar data
            let res02 = await API.get(`/categories?filter=online_store_hierarchy_only`)
            this.categories = res02.data

            //Set all price to 0.00 if customer type == vip
            if(this.currentCustomer){
                if(this.currentCustomer.user.customer.cust_type == 'vip') this.isVIP = true
                else this.isVIP = false
            }

            //check product promo
            this.initPromo()
        }
    }
</script>

<style scoped>
    /* .sidebar {
} */

    .sidebar h5 {
        font-size: 18px;
        text-transform: capitalize;
        letter-spacing: 0.075em;
        line-height: 1.4;
        font-weight: bold;
        /* font-family: "Roboto Slab", serif; */
    }

    .sidebar .contents {
        margin-top: 25px;
    }

    .sidebar ul {
        /* font-family: "Karla", serif; */
        padding-left: 15px;
    }

    .sidebar li {
        list-style: none;
    }

    .sidebar li .active {
        color: #000 !important;
        font-weight: bold;
    }

    .sidebar li a {
        color: #666;
        text-transform: capitalize;
        line-height: 27px;
        font-size: 14px;
        text-decoration: unset;
    }

    .sidebar li .count {
        list-style: none;
        font-size: 12px;
        color: #cacaca;
        margin-left: 10px;
    }

    .contents-right .content-header {
        border-bottom: 2px solid #f6f6f6;
        margin-bottom: 10px;
    }

    .contents-right .content-header .card-title {
        position: absolute;
        bottom: 5px;
        letter-spacing: 0.2em;
        line-height: 1.4;
        /* font-family: "Roboto Slab", serif; */
        text-transform: uppercase;
        font-weight: bold;
        color: #000;
        font-size: 1.42857em;
    }

    .contents-right .products-list {
        border-bottom: 2px solid #f6f6f6;
    }

    .contents-right .products-list .product-view {
        margin-bottom: 25px;
    }

    .contents-right .products-list .product-view .box {
        position: relative;
        background-color: #FFFFFF;
    }

    .img-box {
        position: relative;
        display: block;
        padding-top: 100%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }

    .img-box img.badge-img {
        position: absolute;
        width: 50%;
        top: 5px;
    }

    /* .contents-right .products-list .product-view .box .img-bg-sold-out {
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        position: absolute;
        background-color: black;
        opacity: 0.4;
    } */

    .contents-right .products-list .product-view .box button {
        opacity: 0;
        width: 100%;
        position: absolute;
        bottom: 12px;
        padding: 10px 10px;
        display: block;
        background-color: #fff;
        color: #000;
        border: unset;
        border-radius: unset;
        text-transform: uppercase;
        font-size: 14px!important;
        font-weight: bold;
        /* font-family: "Karla", sans-serif; */
    }

    .contents-right .products-list .product-view .box button.btn-sold-out {
        color: #a91a18;
        opacity: 1;
    }

    .contents-right .products-list .product-view .box .img-box:hover+button {
        opacity: 100;
        transition: 0.2s;
    }

    .contents-right .products-list .product-view .box button:hover {
        opacity: 100;
        transition: 0.2s;
        background-color: #000;
        color: #fff;
    }

    .contents-right .products-list .product-view .box button.btn-sold-out:hover {
        opacity: 100;
        transition: 0;
        background-color: #fff;
        color: #a91a18;
        cursor:initial;
    }

    .contents-right .products-list .product-view a {
        text-decoration: unset;
    }

    .contents-right .products-list .product-view .title {
        display: block;
        font-size: 14px;
        text-transform: none;
        letter-spacing: 0.12em;
        line-height: 1.4;
        /* font-family: "Roboto Slab", serif; */
        font-weight: bold;
        color: #666;
        margin-top: 15px;
    }

    .contents-right .products-list .product-view .price {
        display: block;
        color: #a91a18;
        font-size: 14px;
        /* font-family: "Karla", serif; */
        font-weight: bold;
        margin-top: 8px;
    }
    .contents-right .products-list .product-view .price.compare_at_price {
        text-decoration: line-through;
        color: gray;
        font-size: 12px;
        margin-bottom: -11px;
    }

    .contents-right .pagination-footer {
        width: 100%;
    }

    .contents-right .pagination-footer li a,
    button {
        color: #666;
        border-radius: unset !important;
    }
</style>